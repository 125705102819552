import React, { useContext } from "react"
import Layout from "../../components/layout"
import t from "../../services/GetTranslationValue"
import ThemeContext from "../../context/ThemeContext"

const VerifyEmail = ({ location, data, pageContext }) => {
  const { translations, verificationResult }= useContext(ThemeContext);

  return (
    <Layout location={location} pageContext={pageContext}>
      <div className="not-found">
          <h1 className="not-found-heading">
            {t.get(translations, "email-verification")}
          </h1>
          <p>{verificationResult && verificationResult.msg ? t.get(translations, verificationResult.msg) : '' }</p>
      </div>
    </Layout>
  )
}
export default VerifyEmail
